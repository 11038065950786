import axios from '../../plugins/axios';
import { NEWS_CONTENT_TYPE } from '../../vars';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            title: '',
            code: '',
            blocks: {
                main: {
                    links: []
                },
                trips: {},
                banner: {},
                offer: {},
                tripTypes: {
                    types: {}
                },
                news: {},
                organizers: {},
                reviews: {
                    title: {}
                },
                photos: {
                    pictures: []
                },
                feedback: {},
                col1: {
                    links: []
                },
                col2: {
                    links: []
                },
                col3: {
                    links: []
                },
                contacts: {
                    phoneTitle: {},
                    emailTitle: {}
                },
                technical : {
                    title: {},
                    PrivacyPolicy: {},
                    BookingTerms: {}
                },
                mainMenuAbout: {
                    links: []
                },
                mainMenuBlogger: {
                    links: []
                },
                mainMenuOrganizer: {
                    links: []
                },
                mainMenuTrips: {
                    links: []
                },
                bloggerMenu: {
                    links: []
                },
                organizerMenu: {
                    links: []
                },
                hiddenMenu: {
                    links: []
                },
                questions: [],
                contactsFull: {
                },
            }
        }
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = {
                ...state.entity,
                ...payload,
                ...{
                    blocks: {
                        ...state.entity.blocks,
                        ...payload.blocks
                    }
                }
            };
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                title: '',
                code: '',
                blocks: {
                    main: {
                        links: []
                    },
                    trips: {},
                    banner: {},
                    tripTypes: {
                        types: {}
                    },
                    news: {},
                    organizers: {},
                    photos: {
                        pictures: []
                    },
                    feedback: {},
                    col1: {
                        links: []
                    },
                    col2: {
                        links: []
                    },
                    col3: {
                        links: []
                    },
                    contacts: {},
                    questions: [],
                }
            };
        },
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/admin/page-templates');
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/page-templates/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/admin/page-templates/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/admin/page-templates', state.entity);
            }
            return response.data;
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/page-templates/${ id }`);
        },
    },

    getters: {}
}
