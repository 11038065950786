export const TRIP_STATUS = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    MODERATED: 'moderated',
    NEED_EDITS: 'need_edits',
    NEED_USER: 'need_user',
    DOCUMENTS: 'documents',
    COMPLETED: 'completed',
    INACTIVE: 'inactive'
};

export const TRIP_STATUS_TITLE = {
    [TRIP_STATUS.DRAFT]: 'src\\views\\trips\\list.draft',
    [TRIP_STATUS.ACTIVE]: 'src\\views\\trips\\list.active',
    [TRIP_STATUS.MODERATED]: 'src\\views\\trips\\list.moderated',
    [TRIP_STATUS.NEED_EDITS]: 'src\\views\\trips\\list.need_edits',
    [TRIP_STATUS.NEED_USER]: 'src\\views\\trips\\list.need_user',
    [TRIP_STATUS.DOCUMENTS]: 'src\\views\\trips\\list.documents',
    [TRIP_STATUS.COMPLETED]: 'src\\views\\trips\\list.completed',
    [TRIP_STATUS.INACTIVE]: 'src\\views\\trips\\list.inactive',
};

export const BLOGGER_TRIP_STATUS = {
    DRAFT: 'draft',
    ORGANIZER_MODERATED: 'organizer_moderated',
    BLOGGER_MODERATED: 'bloger_moderated',
    MODERATED: 'moderated',
    ACTIVE: 'active',
    COMPLETED: 'completed',
    DECLINED: 'declined',
};

export const BLOGGER_TRIP_STATUS_TITLE = {
    [BLOGGER_TRIP_STATUS.DRAFT]: 'src\\views\\bloggertrips\\list.draft',
    [BLOGGER_TRIP_STATUS.ORGANIZER_MODERATED]: 'src\\views\\bloggertrips\\list.organizer_moderated',
    [BLOGGER_TRIP_STATUS.BLOGGER_MODERATED]: 'src\\views\\bloggertrips\\list.bloger_moderated',
    [BLOGGER_TRIP_STATUS.MODERATED]: 'src\\views\\bloggertrips\\list.moderated',
    [BLOGGER_TRIP_STATUS.ACTIVE]: 'src\\views\\bloggertrips\\list.active',
    [BLOGGER_TRIP_STATUS.COMPLETED]: 'src\\views\\bloggertrips\\list.completed',
    [BLOGGER_TRIP_STATUS.DECLINED]: 'src\\views\\bloggertrips\\list.declined'
};

export const TRIP_LEVEL = {
    BEGINNER: 'beginner',
    ADVANCED: 'advanced',
    EXPERT: 'expert'
};

export const TRIP_LEVEL_TITLE = {
    [TRIP_LEVEL.BEGINNER]: 'src\\views\\trips\\edit.beginner',
    [TRIP_LEVEL.ADVANCED]: 'src\\views\\trips\\edit.advanced',
    [TRIP_LEVEL.EXPERT]: 'src\\views\\trips\\edit.expert'
};

export const BOOKING_STATUS = {
    STARTED: 'started',
    FILLED: 'filled',
    DEPOSIT: 'deposit',
    PAID: 'paid',
    APPROVED: 'approved',
    NEED_REFUND: 'need_refund',
    CANCELED: 'canceled'
};

export const BOOKING_STATUS_TITLE = {
    [BOOKING_STATUS.STARTED]: 'src\\views\\bookings\\list.started',
    [BOOKING_STATUS.FILLED]: 'src\\views\\bookings\\list.filled',
    [BOOKING_STATUS.DEPOSIT]: 'src\\views\\bookings\\list.deposit',
    [BOOKING_STATUS.PAID]: 'src\\views\\bookings\\list.paid',
    [BOOKING_STATUS.APPROVED]: 'src\\views\\bookings\\list.approved',
    [BOOKING_STATUS.NEED_REFUND]: 'src\\views\\bookings\\list.need_refund',
    [BOOKING_STATUS.CANCELED]: 'src\\views\\bookings\\list.canceled'
};

export const PAYMENT_STATUS = {
    PROCESSING: 'processing',
    SUCCESS: 'success',
    FAIL: 'fail',
    REFUND: 'refund',
    CANCELED: 'canceled'
};

export const PAYMENT_STATUS_TITLE = {
    [PAYMENT_STATUS.PROCESSING]: 'src\\views\\bookings\\detail.processing',
    [PAYMENT_STATUS.SUCCESS]: 'src\\views\\bookings\\detail.success',
    [PAYMENT_STATUS.FAIL]: 'src\\views\\bookings\\detail.fail',
    [PAYMENT_STATUS.REFUND]: 'src\\views\\bookings\\detail.refund',
    [PAYMENT_STATUS.CANCELED]: 'src\\views\\bookings\\detail.canceled'
};

export const REVIEW_STATUS = {
    DRAFT: 'draft',
    MODERATED: 'moderated',
    ACTIVE: 'active',
    REJECTED: 'rejected'
};

export const REVIEW_STATUS_TITLE = {
    [REVIEW_STATUS.DRAFT]: 'src\\views\\reviews\\list.draft',
    [REVIEW_STATUS.MODERATED]: 'src\\views\\reviews\\list.moderated',
    [REVIEW_STATUS.ACTIVE]: 'src\\views\\reviews\\list.active',
    [REVIEW_STATUS.REJECTED]: 'src\\views\\reviews\\list.rejected'
};

export const CHAT_MESSAGE_STATUS = {
    CREATED: 'created',
    DELIVERED: 'delivered',
    READED: 'readed',
    DELETED: 'deleted'
};

export const CHAT_MESSAGE_STATUS_TITLE = {
    [REVIEW_STATUS.CREATED]: 'Создано',
    [REVIEW_STATUS.DELIVERED]: 'Доставлено',
    [REVIEW_STATUS.READED]: 'Прочитано',
    [REVIEW_STATUS.DELETED]: 'Удалено'
};

export const NEWS_CONTENT_TYPE = {
    TEXT: 'text',
    PICTURE: 'picture',
    YOUTUBE: 'youtube'
};

export const SERVER_TASK_TYPE = {
    CREATE_SUBDOMAIN: 'create_subdomain',
    DELETE_SUBDOMAIN: 'delete_subdomain',
    CREATE_VHOST: 'create_vhost',
    DELETE_VHOST: 'delete_vhost'
};

export const SERVER_TASK_TYPE_TITLE = {
    [SERVER_TASK_TYPE.CREATE_SUBDOMAIN]: 'src\\views\\servertasks\\list.create_subdomain',
    [SERVER_TASK_TYPE.DELETE_SUBDOMAIN]: 'src\\views\\servertasks\\list.delete_subdomain',
    [SERVER_TASK_TYPE.CREATE_VHOST]: 'src\\views\\servertasks\\list.create_vhost',
    [SERVER_TASK_TYPE.DELETE_VHOST]: 'src\\views\\servertasks\\list.delete_vhost',
};

export const SERVER_TASK_STATUS = {
    CREATED: 'created',
    PROCESSING: 'processing',
    SUCCESS: 'success',
    FAIL: 'fail'
};

export const SERVER_TASK_STATUS_TITLE = {
    [SERVER_TASK_STATUS.CREATED]: 'src\\views\\servertasks\\list.status_created',
    [SERVER_TASK_STATUS.PROCESSING]: 'src\\views\\servertasks\\list.status_processing',
    [SERVER_TASK_STATUS.SUCCESS]: 'src\\views\\servertasks\\list.status_success',
    [SERVER_TASK_STATUS.FAIL]: 'src\\views\\servertasks\\list.status_fail',
};

export const DIRECTION_TYPE = {
    RUSSIA: 'russia',
    WORLD: 'world'
};

export const DIRECTION_TYPE_TITLE = {
    [DIRECTION_TYPE.RUSSIA]: 'src\\views\\bookingsettings\\list.russia',
    [DIRECTION_TYPE.WORLD]: 'src\\views\\bookingsettings\\list.world'
};

export const TRIP_TYPE = {
    TOUR_LEADER: 'tour_leader',
    HOST: 'host',
    TOUR_BLOGGER: 'tour_blogger',
};

export const TRIP_TYPE_TITLE = {
    [TRIP_TYPE.TOUR_LEADER]: 'src\\views\\trips\\edit.trip_type_tour_leader',
    [TRIP_TYPE.HOST]: 'src\\views\\trips\\edit.trip_type_host',
    [TRIP_TYPE.TOUR_BLOGGER]: 'src\\views\\trips\\edit.trip_type_tour_blogger'
};

export const TRIP_REQUEST_STATUS = {
    CREATED: 'created',
    ANSWERED: 'answered',
    FINISHED: 'finished'
};

export const ACCESS = {
    NONE: 0,
    READ: 1,
    WRITE: 2,
    FULL: 3,
};