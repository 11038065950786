import axios from '../../plugins/axios';
import { NEWS_CONTENT_TYPE } from '../../vars';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            title: '',
            code: '',
            preview: '',
            text: '',
            picture: '',
            contents: [{
                type: NEWS_CONTENT_TYPE.TEXT,
                value: ''
            }]
        }
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                title: '',
                code: '',
                preview: '',
                text: '',
                picture: '',
                contents: [{
                    type: NEWS_CONTENT_TYPE.TEXT,
                    value: ''
                }]
            };
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/admin/news', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/news/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/admin/news/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/admin/news', state.entity);
            }
            return response.data;
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/news/${ id }`);
        },
    },

    getters: {}
}
