import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            title: '',
            url: '',
            values: [{
                name: '',
                en: '',
                ru: ''
            }],
        }
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                title: '',
                url: '',
                values: [{
                    name: '',
                    ru: '',
                    en: ''
                }],
            };
        },
    },

    actions: {
        async common({ commit }) {
            const { data } = await axios.get('/common/translates');
            commit('SET_ENTITIES', data);
            return data;
        },
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/admin/translates', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/translates/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            if(state.entity._id) {
                return await axios.put(`/admin/translates/${ state.entity._id }`, state.entity);
            } else {
                return await axios.post('/admin/translates', state.entity);
            }
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/translates/${ id }`);
        },
    },

    getters: {}
}
