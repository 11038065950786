import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            code: '',
            name: '',
            values: [{
                code: '',
                title: '',
                picture: '',
                text: ''
            }],
        }
    },

    mutations: {
        SET_DICTIONARIES(state, payload) {
            for(const code in payload) {
                state[code] = payload[code];
            }
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                code: '',
                name: '',
                values: [{
                    code: '',
                    title: '',
                    picture: '',
                    text: ''
                }],
            };
        },
    },

    actions: {
        async common({ commit }) {
            const { data } = await axios.get('/common/dictionaries');
            commit('SET_DICTIONARIES', data);
            return data;
        },
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/admin/dictionaries', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/dictionaries/${ id }`);
            commit('SET_ENTITY', data);
        },
        async getByCode({ commit }, { code }) {
            const { data } = await axios.get(`/common/dictionaries/${ code }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/admin/dictionaries/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/admin/dictionaries', state.entity);
            }
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/dictionaries/${ id }`);
        },
    },

    getters: {}
}
