import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            name: '',
            direction: [],
            pictures: [],
            mainPictureIndex: 0,
            tourOperator: '',
            groupSizeFrom: '',
            groupSizeTo: '',
            groupAgeFrom: '',
            groupAgeTo: '',
            language: '',
            hygiene: '',
            mainPlaces: [
                {
                    picture: '',
                    description: '',
                }
            ],
            benefits: [''],
            included: [
                {
                    title: '',
                    description: '',
                    picture: '',
                }
            ],
            notIncluded:[
                {
                    title: '',
                    description: '',
                    picture: '',
                }
            ],
            advantages: [
                {
                    title: '',
                    description: '',
                }
            ],
            activitiesDescription: '',
            activities: [
                {
                    picture: '',
                    description: '',
                }
            ],
            mainType: '',
            additionalTypes: [
                ''
            ],
            tags: [],
            arrivals: [
                {
                    start: null,
                    finish: null,
                    price: 0,
                    currency: 'RUB',
                },
            ],
            programs: [
                {
                    title: '',
                    description: '',
                    picture: '',
                },
            ],
            accommodationsDescription: '',
            accommodations: [
                {
                    picture: '',
                    description: '',
                }
            ],
            transportsDescription: '',
            transports: [
                {
                    picture: '',
                    description: '',
                }
            ],
            questions: [
                {
                    question: '',
                    answer: '',
                }
            ]
        }
    },

    mutations: {
        CLEAR_ENTITIES(state) {
            state.entities = [];
            state.entitiesCount = 0;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                name: '',
                direction: [],
                pictures: [],
                mainPictureIndex: 0,
                tourOperator: '',
                groupSizeFrom: '',
                groupSizeTo: '',
                groupAgeFrom: '',
                groupAgeTo: '',
                language: '',
                hygiene: '',
                mainPlaces: [
                    {
                        picture: '',
                        description: '',
                    }
                ],
                benefits: [''],
                included: [
                    {
                        title: '',
                        description: '',
                        picture: '',
                    }
                ],
                notIncluded:[
                    {
                        title: '',
                        description: '',
                        picture: '',
                    }
                ],
                advantages: [
                    {
                        title: '',
                        description: '',
                    }
                ],
                activitiesDescription: '',
                activities: [
                    {
                        picture: '',
                        description: '',
                    }
                ],
                mainType: '',
                additionalTypes: [
                    ''
                ],
                tags: [],
                arrivals: [
                    {
                        start: null,
                        finish: null,
                        price: 0,
                        currency: 'RUB',
                    },
                ],
                programs: [
                    {
                        title: '',
                        description: '',
                        picture: '',
                    },
                ],
                accommodationsDescription: '',
                accommodations: [
                    {
                        picture: '',
                        description: '',
                    }
                ],
                transportsDescription: '',
                transports: [
                    {
                        picture: '',
                        description: '',
                    }
                ],
                questions: [
                    {
                        question: '',
                        answer: '',
                    }
                ]
            };
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload.items;
            state.entitiesCount = payload.totalCount;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        SET_ENTITY_VALUE(state, { prop_1, prop_2, prop_3, value }) {
            if(typeof prop_3 !== 'undefined') {
                state.entity[prop_1][prop_2][prop_3] = value;
            } else if(typeof prop_2 !== 'undefined') {
                state.entity[prop_1][prop_2] = value;
            } else if(typeof prop_1 !== 'undefined') {
                state.entity[prop_1] = value;
            } else {
                state.entity = value;
            }
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null, page = 1, itemsPerPage = 10 }) {
            const { data } = await axios.get('/admin/trips', { params: { filter, sorting, page, itemsPerPage } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/trips/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/admin/trips/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/admin/trips', state.entity);
            }
            return response;
        },
        async setStatus({ commit, state }, { id, status }) {
            await axios.put(`/admin/trips/status/${ id }/${ status }`);
        },
        async setSorting({ commit, state }, { id, sorting }) {
            await axios.put(`/admin/trips/sorting/${ id }/${ sorting }`);
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/trips/${ id }`);
        },
        async favoriteFetch({ commit }) {
            const { data } = await axios.get('/admin/trips/favorites');
            commit('SET_ENTITIES', data);
            return data;
        },
        async favoriteAdd({ commit }, { id }) {
            await axios.put(`/admin/trips/favorites/${ id }`);
        },
        async favoriteDelete({ commit }, { id }) {
            await axios.delete(`/admin/trips/favorites/${ id }`);
        },
    },

    getters: {}
}
