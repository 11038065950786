import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            title: '',
            preview: '',
            text: '',
            picture: '',
        }
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                title: '',
                preview: '',
                text: '',
                picture: '',
            };
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/admin/bookings', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async fetchNeedRefund({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/admin/bookings/need-refund', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/bookings/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            if(state.entity._id) {
                await axios.put(`/admin/bookings/${ state.entity._id }`, state.entity);
            } else {
                await axios.post('/admin/bookings', state.entity);
            }
        },
        async refund({ commit }, { id, amount, comment }) {
            await axios.put(`/admin/bookings/${ id }/refund`, { amount, comment });
        },
        async setStatus({ commit, state }, { id, status }) {
            await axios.put(`/admin/bookings/status/${ id }/${ status }`);
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/bookings/${ id }`);
        },
    },

    getters: {}
}
