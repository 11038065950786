import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            title: '',
            preview: '',
            text: '',
            picture: '',
        }
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload.items;
            state.entitiesCount = payload.totalCount;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                title: '',
                preview: '',
                text: '',
                picture: '',
            };
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null, page = 1, itemsPerPage = 10 }) {
            const { data } = await axios.get('/admin/users', { params: { filter, sorting, page, itemsPerPage } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/users/${ id }`);
            commit('SET_ENTITY', data);
        },
        async setAdminCode({ commit, state }, { id, code }) {
            await axios.put(`/admin/users/admin-code/${ id }`, { code });
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/admin/users/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/admin/users', state.entity);
            }
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/users/${ id }`);
        },
    },

    getters: {}
}
