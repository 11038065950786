import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: {
            name: 'trips-list'
        }
    }, {
        path: '/user/authorization',
        name: 'user-authorization',
        component: () => import('../views/user/Authorization.vue')
    }, {
        path: '/user/confirmation',
        name: 'user-confirmation',
        component: () => import('../views/user/Confirmation.vue')
    }, {
        path: '/trips',
        name: 'trips-list',
        component: () => import('../views/trips/List.vue')
    }, {
        path: '/trips/create',
        name: 'trips-create',
        component: () => import('../views/trips/Edit.vue')
    }, {
        path: '/trips/edit/:id',
        name: 'trips-edit',
        component: () => import('../views/trips/Edit.vue')
    }, {
        path: '/bookings',
        name: 'bookings-list',
        component: () => import('../views/bookings/List.vue')
    }, {
        path: '/bookings/need-refund',
        name: 'bookings-need-refund',
        component: () => import('../views/bookings/NeedRefund.vue')
    }, {
        path: '/bookings/:id',
        name: 'bookings-detail',
        component: () => import('../views/bookings/Detail.vue')
    }, {
        path: '/documents',
        name: 'documents-list',
        component: () => import('../views/documents/List.vue')
    }, {
        path: '/documents/create',
        name: 'documents-create',
        component: () => import('../views/documents/Edit.vue')
    }, {
        path: '/documents/edit/:id',
        name: 'documents-edit',
        component: () => import('../views/documents/Edit.vue')
    }, {
        path: '/payments',
        name: 'payments-list',
        component: () => import('../views/payments/List.vue')
    }, {
        path: '/news',
        name: 'news-list',
        component: () => import('../views/news/List.vue')
    }, {
        path: '/news/create',
        name: 'news-create',
        component: () => import('../views/news/Edit.vue')
    }, {
        path: '/news/edit/:id',
        name: 'news-edit',
        component: () => import('../views/news/Edit.vue')
    }, {
        path: '/reports',
        name: 'reports-list',
        component: () => import('../views/reports/List.vue')
    }, {
        path: '/reports/create',
        name: 'reports-create',
        component: () => import('../views/reports/Edit.vue')
    }, {
        path: '/reports/edit/:id',
        name: 'reports-edit',
        component: () => import('../views/reports/Edit.vue')
    }, {
        path: '/users',
        name: 'users-list',
        component: () => import('../views/users/List.vue')
    }, {
        path: '/users/create',
        name: 'users-create',
        component: () => import('../views/users/Edit.vue')
    }, {
        path: '/users/edit/:id',
        name: 'users-edit',
        component: () => import('../views/users/Edit.vue')
    }, {
        path: '/feedbacks',
        name: 'feedback-list',
        component: () => import('../views/feedbacks/List.vue')
    }, {
        path: '/feedbacks/:id',
        name: 'feedback-detail',
        component: () => import('../views/feedbacks/Detail.vue')
    }, {
        path: '/blogger-resources',
        name: 'blogger-resources-list',
        component: () => import('../views/bloggerResources/List.vue')
    }, {
        path: '/blogger-resources/create',
        name: 'blogger-resources-create',
        component: () => import('../views/bloggerResources/Edit.vue')
    }, {
        path: '/blogger-resources/edit/:id',
        name: 'blogger-resources-edit',
        component: () => import('../views/bloggerResources/Edit.vue')
    }, {
        path: '/quizes',
        name: 'quiz-list',
        component: () => import('../views/quizes/List.vue')
    }, {
        path: '/quizes/:id',
        name: 'quiz-detail',
        component: () => import('../views/quizes/Detail.vue')
    }, {
        path: '/waiting-list',
        name: 'waiting-list',
        component: () => import('../views/waitingLists/List.vue')
    }, {
        path: '/dictionaries',
        name: 'dictionaries-list',
        component: () => import('../views/dictionaries/List.vue')
    }, {
        path: '/dictionaries/create',
        name: 'dictionaries-create',
        component: () => import('../views/dictionaries/Edit.vue')
    }, {
        path: '/dictionaries/edit/:id',
        name: 'dictionaries-edit',
        component: () => import('../views/dictionaries/Edit.vue')
    }, {
        path: '/reviews',
        name: 'reviews-list',
        component: () => import('../views/reviews/List.vue')
    }, {
        path: '/reviews/create',
        name: 'reviews-create',
        component: () => import('../views/reviews/Edit.vue')
    }, {
        path: '/reviews/edit/:id',
        name: 'reviews-edit',
        component: () => import('../views/reviews/Edit.vue')
    }, {
        path: '/page-templates',
        name: 'page-templates-list',
        component: () => import('../views/pageTemplates/List.vue')
    }, {
        path: '/page-templates/create',
        name: 'page-templates-create',
        component: () => import('../views/pageTemplates/Edit.vue')
    }, {
        path: '/page-templates/edit/:id',
        name: 'page-templates-edit',
        component: () => import('../views/pageTemplates/Edit.vue')
    }, {
        path: '/quiz-dictionaries',
        name: 'quiz-dictionaries-list',
        component: () => import('../views/quizDictionaries/List.vue')
    }, {
        path: '/quiz-dictionaries/create',
        name: 'quiz-dictionaries-create',
        component: () => import('../views/quizDictionaries/Edit.vue')
    }, {
        path: '/quiz-dictionaries/edit/:id',
        name: 'quiz-dictionaries-edit',
        component: () => import('../views/quizDictionaries/Edit.vue')
    }, {
        path: '/server-tasks',
        name: 'server-tasks-list',
        component: () => import('../views/serverTasks/List.vue')
    }, {
        path: '/server-tasks/create',
        name: 'server-tasks-create',
        component: () => import('../views/serverTasks/Edit.vue')
    }, {
        path: '/server-tasks/edit/:id',
        name: 'server-tasks-edit',
        component: () => import('../views/serverTasks/Edit.vue')
    }, {
        path: '/booking-settings',
        name: 'booking-settings-list',
        component: () => import('../views/bookingSettings/List.vue')
    }, {
        path: '/booking-settings/create',
        name: 'booking-settings-create',
        component: () => import('../views/bookingSettings/Edit.vue')
    }, {
        path: '/booking-settings/edit/:id',
        name: 'booking-settings-edit',
        component: () => import('../views/bookingSettings/Edit.vue')
    }, {
        path: '/trip-requests',
        name: 'trip-requests-list',
        component: () => import('../views/tripRequests/List.vue')
    }, {
        path: '/trip-requests/create',
        name: 'trip-requests-create',
        component: () => import('../views/tripRequests/Edit.vue')
    }, {
        path: '/trip-requests/edit/:id',
        name: 'trip-requests-edit',
        component: () => import('../views/tripRequests/Edit.vue')
    }, {
        path: '/blogger-trips',
        name: 'blogger-trips-list',
        component: () => import('../views/bloggerTrips/List.vue')
    }, {
        path: '/blogger-trips/create',
        name: 'blogger-trips-create',
        component: () => import('../views/bloggerTrips/Edit.vue')
    }, {
        path: '/blogger-trips/edit/:id',
        name: 'blogger-trips-edit',
        component: () => import('../views/bloggerTrips/Edit.vue')
    }, {
        path: '/translates',
        name: 'translates-list',
        component: () => import('../views/translates/List.vue')
    }, {
        path: '/translates/create',
        name: 'translates-create',
        component: () => import('../views/translates/Edit.vue')
    }, {
        path: '/translates/edit/:id',
        name: 'translates-edit',
        component: () => import('../views/translates/Edit.vue')
    }, {
        path: '/event-templates',
        name: 'event-templates-list',
        component: () => import('../views/eventTemplates/List.vue')
    }, {
        path: '/event-templates/create',
        name: 'event-templates-create',
        component: () => import('../views/eventTemplates/Edit.vue')
    }, {
        path: '/event-templates/edit/:id',
        name: 'event-templates-edit',
        component: () => import('../views/eventTemplates/Edit.vue')
    }
]

const router = new VueRouter({
    routes,
    base: '/',   
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router
