import { required, email, max, ext, size, image } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
    ...required,
    message: 'Пожалуйста, заполните поле'
});

extend('max', {
    ...max,
    message: 'Колличество символов не должно превышать {length}'
});

extend('email', {
    ...email,
    message: 'Пожалуйста, введите валидный e-mail'
});

extend('ext', {
    ...ext,
    message: 'Недопустимое расширение файла'
});

extend('size', {
    ...size,
    message: (field, { size }) => {
        return `Размер файла не должен превышать ${ size / 1024 } МБ`
    }
});
