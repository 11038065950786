import axios from '../../plugins/axios';

const EMPTY_USER = {
    firstname: '',
    lastname: '',
    avatar: '',
    birthday: '',
    city: '',
    site: '',
    images: [],
    instagram: '',
    phone: '',
    reviews: '',
    about: '',
    adminAccess: {
        trips: 0,
        bookings: 0,
        payments: 0,
        documents: 0,
        reviews: 0,
        news: 0,
        reports: 0,
        feedbacks: 0,
        quizes: 0,
        waiting_lists: 0,
        users: 0,
        trip_requests: 0,
        blogger_trips: 0,
        blogger_resources: 0,
        dictionaries: 0,
        quiz_dictionaries: 0,
        page_templates: 0,
        booking_settings: 0,
        server_tasks: 0,
        translates: 0,
        event_templates: 0,
    },
};

export default {
    namespaced: true,

    state: {
        user: JSON.parse(JSON.stringify(EMPTY_USER)), 
        auth: {}
    },

    mutations: {
        SET_USER(state, payload) {
            state.user = {
                ...state.user,
                ...payload
            };
            if(payload.token) {
                localStorage.setItem('shinytravel-admin-token', payload.token);
            }
        },
        CLEAR_USER(state) {
            state.user = JSON.parse(JSON.stringify(EMPTY_USER));
            localStorage.removeItem('shinytravel-admin-token');
        },
        SET_VALUE(state, { prop, value }) {
            state.user[prop] = value;
        },
        SET_AUTH(state, payload) {
            state.auth = payload;
        },
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/common/auth/profile');
            if(data.admin) {
                commit('SET_USER', data);
            } else {
                commit('CLEAR_USER');
            }
            return data;
        },
        async save({ commit, state }) {
            let response = {};
            if(state.user._id) {
                response = await axios.put('/common/auth/profile', state.user);
            }
        },
        async login({ commit }, { phone }) {
            const { data } = await axios.post('/common/auth/login', { phone: phone.replace(/ /g, '') });
            commit('SET_AUTH', data);
            return data;
        },
        async confirm({ state, commit }, { code }) {
            const { data } = await axios.post('/common/auth/confirm', { ...state.auth, ...{ code } });
            commit('SET_USER', {
                ...state.user, 
                ...data
            });
            return data;
        },
        async logout({ commit }) {
            commit('CLEAR_USER');
        }
    },

    getters: {}
}