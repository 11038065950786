import axios from '../../plugins/axios';

const EMPTY_ENTITY = {
    _id: null,
    title: '',
    code: '',
    description: '',
    user: {
        event: {
            subject: {},
            text: {},
            link: {},
            linkText: {}
        },
        email: {
            template: {},
            subject: {},
            text: {},
            link: {},
            linkText: {}
        },
        telegram: {
            subject: {},
            text: {},
            link: {},
            linkText: {},
        },
    },
    admin: {
        email: {
            template: {},
            subject: {},
            text: {},
            link: {},
            linkText: {}
        },
        telegram: {
            subject: {},
            text: {},
            link: {},
            linkText: {},
        }
    }
};

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: EMPTY_ENTITY
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            for(const key1 in EMPTY_ENTITY) {
                if(['user', 'admin'].indexOf(key1) >= 0) {
                    if(!payload[key1]) {
                        payload[key1] = {};
                    }
                    for(const key2 in EMPTY_ENTITY[key1]) {
                        if(!payload[key1][key2]) {
                            payload[key1][key2] = {};
                        }
                        for(const key3 in EMPTY_ENTITY[key1][key2]) {
                            if(!payload[key1][key2][key3]) {
                                payload[key1][key2][key3] = {};
                            }
                        }
                    }
                }
            }
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                ...{},
                ...EMPTY_ENTITY
            };
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/admin/event-templates', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/event-templates/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            if(state.entity._id) {
                return await axios.put(`/admin/event-templates/${ state.entity._id }`, state.entity);
            } else {
                return await axios.post('/admin/event-templates', state.entity);
            }
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/event-templates/${ id }`);
        },
    },

    getters: {}
}
